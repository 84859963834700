@import 'variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.amm-form {
  .form-group {
    &.position-relative {
      z-index: 123;
    }
  }

  .vmc-error {
    color: $color_red;
    font-size: 13px;
    font-weight: bold;
    padding-top: 4px;
  }

  textarea,
  input[type='text'],
  input[type='password'],
  input[type='number'],
  input[type='email'],
  label {
    font-size: 14px;
    width: 100%;
    letter-spacing: 0;
    line-height: 20px;
  }

  label {
    font-weight: bold;
    text-align: left;
  }

  textarea,
  input[type='text'],
  input[type='password'],
  input[type='email'],
  input[type='number'] {
    padding: 13px 20px;
    border: 1px solid $color_light-gray;
    border-radius: 12px;
    outline: none;

    &:-moz-placeholder,
    &:-ms-input-placeholder,
    &::-moz-placeholder,
    &::-webkit-input-placeholder {
      font-weight: lighter;
    }

    &.ng-invalid.ng-touched {
      border: 1px solid $color_red;
    }

    &:disabled,
    &:focus {
      border: 1px solid #000;
    }
  }

  label.radio {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: nowrap;
    margin: 12px 0;
    cursor: pointer;
    position: relative;

    .design {
      min-width: 24px;
      width: 24px;
      height: 24px;
      border: 2px solid $color_light-gray;
      border-radius: 100%;
      margin-right: 16px;
      position: relative;

      &::before,
      &::after {
        content: '';
        display: block;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        position: absolute;
        transform: scale(0);
        transform-origin: center center;
        left: -2px;
        top: -2px;
      }

      &:before {
        background: $color_light-gray;
        opacity: 0;
        transition: 0.2s;
        position: absolute;
      }

      &::after {
        background: $color-red;
        opacity: 0.4;
        transition: 0.4s;
      }
    }

    input[type='radio'] {
      opacity: 0;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: -1;

      &:checked {
        & + .design {
          &::before {
            opacity: 1;
            background: $color_red;
            transform: scale(0.6);
          }
        }
      }

      &.amm-invalid.amm-touched,
      &.ng-invalid.ng-touched {
        & + .design {
          border: 2px solid $color_red;
        }
      }

      &:focus {
        & + .design {
          border: 2px solid $color_red;
        }
      }
    }
  }

  input[type='checkbox'] {
    &:not(:checked),
    &:checked {
      position: absolute;
      left: -9999px;

      & + label {
        position: relative;
        padding-left: 40px;
        cursor: pointer;
        line-height: 1.2;
        font-weight: normal;

        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1.55em;
          height: 1.55em;
          background: #fff;
          border-radius: 4px;
          box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
        }

        &:after {
          content: '\2713\0020';
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 5px;
          font-size: 0.9rem;
          line-height: 0.7;
          color: $color_red;
          transition: all 0.2s;
          font-weight: bold;
        }
      }
    }

    &:not(:checked) + label:after {
      opacity: 0;
    }

    &:checked + label:after {
      opacity: 1;
    }

    &:disabled:checked + label:after {
      color: #999;
    }

    &.amm-invalid.amm-touched,
    &.ng-invalid.ng-touched {
      & + label:before {
        border: 2px solid $color_red;
      }
    }

    &:disabled + label {
      color: #aaa;
      cursor: not-allowed;

      &:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #ddd;
      }
    }

    &:checked:focus,
    &:not(:checked):focus {
      & + label:before {
        border: 2px solid $color_red;
      }
    }
  }

  .ng-select {
    &.amm-invalid.amm-touched,
    &.ng-invalid.ng-touched {
      .ng-select-container {
        border: 1px solid $color_red;
      }
    }

    .ng-dropdown-panel.ng-select-bottom {
      border: 1px solid #000;
    }

    .ng-dropdown-panel-items.scroll-host {
      &::-webkit-scrollbar {
        width: 16px;
      }

      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 10px;
        border-radius: 10px;
        background: rgba(228, 20, 41, 1);
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
    }

    &.ng-select-disabled {
      opacity: 0.3;
    }

    .ng-arrow-wrapper {
      display: none;
    }

    .ng-clear {
      color: $color_light-gray;
      font-weight: bold;
    }

    .ng-dropdown-panel {
      border-color: $color_light-gray;
    }

    &.ng-select-focused:not(.ng-select-opened) > .ng-select-container {
      border-color: $color_light-gray;
    }

    &.ng-select-opened > .ng-select-container {
      border: 1px solid #000;
    }

    .ng-placeholder {
      font-size: 14px;
    }

    .ng-select-container {
      border: 1px solid #a7a7a7;
      border-radius: 10px;
      min-height: 50px;

      .ng-value-container {
        .ng-input {
          top: 12px;
        }
      }
    }
  }

  ngx-intl-tel-input {
    &.ng-invalid.ng-touched {
      .amm-ngx-intl-tel-input {
        border: 1px solid $color_red;
      }
    }

    .amm-ngx-intl-tel-input {
      padding: 13px 20px 13px 52px;
      border: 1px solid $color_light-gray;
      border-radius: 12px;
      outline: none;
      width: 100%;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;

      &:focus {
        border: 1px solid #000;
        box-shadow: none;
      }
    }

    div.iti {
      width: 100%;
    }

    .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
      border-top-left-radius: 12px;
      border-bottom-left-radius: 12px;
    }

    .dropdown-menu .search-container {
      input#country-search-box {
        padding: 12px 20px;
      }
    }
  }
}
