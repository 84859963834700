@import 'variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap');

@font-face {
  font-family: 'Uni Neue';
  src: url('~apps/my-amica/src/assets/fonts/Fontfabric - UniNeueThin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('~apps/my-amica/src/assets/fonts/Fontfabric - UniNeueLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('~apps/my-amica/src/assets/fonts/Fontfabric - UniNeueBook.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('~apps/my-amica/src/assets/fonts/Fontfabric - UniNeueRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('~apps/my-amica/src/assets/fonts/Fontfabric - UniNeueBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('~apps/my-amica/src/assets/fonts/Fontfabric - UniNeueBlack.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Uni Neue';
  src: url('~apps/my-amica/src/assets/fonts/Fontfabric - UniNeueHeavy.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: 'Lato', sans-serif;
}

h2,
h3,
p {
  letter-spacing: 0;
  font-weight: normal;
}

h1 {
  font-size: 55px;
  line-height: 60px;

  @include media-breakpoint-down(md) {
    font-size: 46px;
    line-height: 56px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 36px;
    line-height: 46px;
  }
}

h2 {
  font-size: 46px;
  line-height: 56px;

  @include media-breakpoint-down(md) {
    font-size: 36px;
    line-height: 46px;
  }
}

h3 {
  font-size: 40px;
  line-height: 50px;

  @include media-breakpoint-down(lg) {
    font-size: 36px;
    line-height: 46px;
  }

  @include media-breakpoint-down(md) {
    font-size: 27px;
    line-height: 32px;
  }
}

h4 {
  font-size: 30px;
  line-height: 33px;
}

p {
  margin-bottom: 0;
}

.p-94 {
  font-size: 94px;
  line-height: 70px;

  @include media-breakpoint-down(lg) {
    font-size: 85px;
  }

  @include media-breakpoint-down(md) {
    font-size: 76px;
  }
}

.p-80 {
  font-size: 80px;
  line-height: 70px;

  @include media-breakpoint-down(lg) {
    font-size: 60px;
    line-height: 50px;
  }

  @include media-breakpoint-down(md) {
    font-size: 46px;
    line-height: 40px;
  }
}

.p-60 {
  font-size: 60px;
  line-height: 50px;

  @include media-breakpoint-down(lg) {
    font-size: 46px;
  }

  @include media-breakpoint-down(md) {
    font-size: 36px;
  }
}

.p-55 {
  font-size: 55px;
  line-height: 50px;

  @include media-breakpoint-down(lg) {
    font-size: 38px;
    line-height: 36px;
  }

  @include media-breakpoint-down(md) {
    font-size: 32px;
    line-height: 30px;
  }
}

.p-50 {
  font-size: 50px;
  line-height: 40px;

  @include media-breakpoint-down(lg) {
    font-size: 35px;
    line-height: 44px;
  }

  @include media-breakpoint-down(md) {
    font-size: 30px;
    line-height: 40px;
  }
}

.p-48 {
  font-size: 48px;
  line-height: 50px;

  @include media-breakpoint-down(lg) {
    font-size: 33px;
    line-height: 42px;
  }

  @include media-breakpoint-down(md) {
    font-size: 28px;
    line-height: 36px;
  }
}

.p-40 {
  font-size: 40px;
  line-height: 1;

  @include media-breakpoint-down(lg) {
    font-size: 36px;
  }

  @include media-breakpoint-down(md) {
    font-size: 32px;
  }
}

.p-35 {
  font-size: 35px;
  line-height: 44px;

  @include media-breakpoint-down(lg) {
    font-size: 30px;
    line-height: 40px;
  }

  @include media-breakpoint-down(md) {
    font-size: 27px;
    line-height: 36px;
  }
}

.p-30 {
  font-size: 30px;
  line-height: 40px;

  @include media-breakpoint-down(lg) {
    font-size: 27px;
    line-height: 36px;
  }

  @include media-breakpoint-down(md) {
    font-size: 24px;
    line-height: 32px;
  }
}

.p-28 {
  font-size: 28px;
  line-height: 36px;

  @include media-breakpoint-down(lg) {
    font-size: 24px;
    line-height: 32px;
  }

  @include media-breakpoint-down(md) {
    font-size: 21px;
    line-height: 27px;
  }
}

.p-27 {
  font-size: 27px;
  line-height: 35px;

  @include media-breakpoint-down(lg) {
    font-size: 23px;
    line-height: 30px;
  }

  @include media-breakpoint-down(md) {
    font-size: 20px;
    line-height: 25px;
  }
}

.p-25 {
  font-size: 25px;
  line-height: 30px;

  @include media-breakpoint-down(lg) {
    font-size: 21px;
    line-height: 32px;
  }

  @include media-breakpoint-down(md) {
    font-size: 18px;
    line-height: 27px;
  }
}

.p-24 {
  font-size: 24px;
  line-height: 36px;

  @include media-breakpoint-down(lg) {
    font-size: 21px;
    line-height: 32px;
  }

  @include media-breakpoint-down(md) {
    font-size: 18px;
    line-height: 27px;
  }
}

.p-22 {
  font-size: 22px;
  line-height: 30px;

  @include media-breakpoint-down(md) {
    font-size: 18px;
    line-height: 27px;
  }
}

.p-20 {
  font-size: 20px;
  line-height: 27px;

  @include media-breakpoint-down(md) {
    font-size: 16px;
    line-height: 22px;
  }
}

.p-18 {
  font-size: 18px;
  line-height: 28px;

  @include media-breakpoint-down(md) {
    font-size: 16px;
    line-height: 22px;
  }

  @include media-breakpoint-down(sm) {
    font-size: 14px;
    line-height: 19px;
  }
}

.p-16 {
  font-size: 16px;
  line-height: 22px;

  @include media-breakpoint-down(md) {
    font-size: 13px;
    line-height: 19px;
  }
}

.p-14 {
  font-size: 14px;
  line-height: 20px;

  @include media-breakpoint-down(md) {
    font-size: 12px;
    line-height: 18px;
  }
}

.p-12 {
  font-size: 12px;
  line-height: 20px;

  @include media-breakpoint-down(md) {
    font-size: 9px;
    line-height: 16px;
  }
}

.p-11 {
  font-size: 10px;
  line-height: 12px;

  @include media-breakpoint-up(md) {
    font-size: 11px;
    line-height: 14px;
  }
}

.p-9 {
  font-size: 9px;
  line-height: 10px;
}

.l-h-1 {
  line-height: 1;
}

.l-h-1-2 {
  line-height: 1.2;
}

.f-lato {
  font-family: 'Lato', sans-serif;
}

.f-uni {
  font-family: 'Uni Neue', sans-serif;
}

.c-white {
  color: #fff;
}

.c-black {
  color: #000;

  &:hover {
    color: #000;
  }
}

.c-gray-2 {
  color: $color-gray-2;

  &:hover {
    color: $color-gray-2;
  }
}

.c-red {
  color: $color_red;

  &:hover {
    color: $color_red;
  }
}

.c-green {
  color: $color_green;
}

.t-justify {
  text-align: justify;
}
