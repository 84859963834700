@use 'mixins' as m;
@import 'variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

.amm-modal-content {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 3px 6px #00000029;

  button.close-btn {
    background: transparent;
    font-size: 31px;
    line-height: 1;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    outline: none;
    @include m.hover-opacity();

    &:focus {
      box-shadow: 0 0 3px 2px $color_strong_red;
    }
  }

  img {
    display: block;
    margin: 0 auto;
    max-width: 100%;
  }
}

.amc-override {
  .modal-content {
    background: transparent;
    border: none;
  }

  @include media-breakpoint-up(sm) {
    .modal-sm {
      max-width: 536px;
    }
  }
}

.amc-override-product {
  .modal-dialog {
    width: 720px;
    max-width: 100%;
    margin: 0 auto;
  }

  .amm-modal-content {
    padding: 10px;
  }

  .modal-content {
    background: transparent;
    border: none;
  }

  @include media-breakpoint-up(sm) {
    .modal-sm {
      max-width: 536px;
    }
  }
}

.amc-override-video {
  .modal-dialog {
    width: 720px;
    max-width: 100%;
    margin: 0 auto;
  }

  .amm-modal-content {
    padding: 10px;
  }

  .modal-content {
    background: transparent;
    border: none;
  }

  @include media-breakpoint-up(sm) {
    .modal-sm {
      max-width: 536px;
    }
  }
}

.modal-content {
  animation: scaleUp 0.75s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes scaleUp {
  0% {
    transform: scale(0.8) translateY(1000px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
}
