$header_height: 136px;
$header_height_scrolled: 100px;
$footer_height: 136px;

$color_strong_red: #bf0d13;
$color_red: #e21227;
$color_red-2: #e61429;
$color_red-3: #c70000;
$color_green: #078d02;
$color_lighter-red: #ffe0e3;
$color_light_gray: #e3e3e3;
$color_gray: #c4c4c4;
$color_gray-2: #8c8c8c;
