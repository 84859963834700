@import 'variables';
@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins/breakpoints';

button,
a {
  border: none;

  &.button {
    text-align: center;
    box-shadow: 0 3px 6px #00000029;
    display: inline-block;
    text-decoration: none;
    cursor: pointer;

    &:disabled {
      opacity: 0.5;
    }
  }

  &.button-red {
    background: $color_strong_red;
    background: -webkit-gradient(linear, left top, right top, from($color-strong_red), to($color_red));
    background: linear-gradient(90deg, $color-strong_red, $color_red);
    color: #fff;

    @include media-breakpoint-down(md) {
      padding: 10px 14px;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 3px 2px $color_strong_red;
    }

    &:not(:disabled):active,
    &:not(:disabled):hover {
      text-decoration: none;
      color: $color_red;
      background: #fff;
    }
  }

  &.button-white {
    background: #fff;
    color: $color_red;
    border-radius: 16px;

    @include media-breakpoint-down(md) {
      padding: 10px 14px;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 3px 2px $color_strong_red;
    }

    &:not(:disabled):active,
    &:not(:disabled):hover {
      text-decoration: none;
      color: #fff;
      background: $color_strong_red;
      background: -webkit-gradient(linear, left top, right top, from($color-strong_red), to($color_red));
      background: linear-gradient(90deg, $color-strong_red, $color_red);
    }
  }

  &.button-green {
    background: #fff;
    color: #006633;
    border-radius: 16px;

    @include media-breakpoint-down(md) {
      padding: 10px 14px;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 3px 2px #006633;
    }

    &:not(:disabled):active,
    &:not(:disabled):hover {
      text-decoration: none;
      color: #fff;
      background: #006633;
    }
  }

  &.button-transparent {
    background: transparent;
    color: $color_red;
    border-radius: 16px;

    @include media-breakpoint-down(md) {
      padding: 10px 14px;
    }

    &:not(:disabled):hover {
      text-decoration: none;
      color: #fff;
      background: $color_strong_red;
      background: -webkit-gradient(linear, left top, right top, from($color-strong_red), to($color_red));
      background: linear-gradient(90deg, $color-strong_red, $color_red);
    }
  }

  &.button-big {
    padding: 12px 49px;
    line-height: 35px;
    font-size: 20px;
    border-radius: 16px;

    @include media-breakpoint-down(md) {
      padding: 10px 38px;
      font-size: 16px;
      line-height: 21px;
    }
  }

  &.button-normal {
    min-width: 175px;
    padding: 14px 38px;
    font-size: 16px;
    line-height: 20px;
    border-radius: 16px;

    @include media-breakpoint-down(md) {
      padding: 10px 14px;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &.button-small {
    min-width: 146px;
    padding: 9px 22px;
    border-radius: 8px;
    font-size: 10px;

    @include media-breakpoint-down(md) {
      padding: 7px 11px;
    }
  }

  &.button-tiny {
    padding: 8px 12px;
    line-height: 8px;
  }

  &.am-border-white {
    border: 2px solid #fff;
    &:not(:disabled):hover {
      border: 2px solid $color_red;
    }
  }

  &.am-border-red {
    border: 2px solid $color_red;
    &:not(:disabled):hover {
      border: 2px solid #fff;
    }
  }

  &.am-border-green {
    border: 2px solid #006633;
    &:not(:disabled):hover {
      border: 2px solid #fff;
    }
  }
}
