@mixin pseudo($loc: before, $content: '', $position: absolute) {
  position: relative;

  &::#{$loc} {
    content: $content;
    position: $position;
  }
}

@mixin hover-opacity() {
  transition: opacity 0.2s ease-in-out;
  &:hover,
  &:focus {
    opacity: 0.8;
  }
}

@mixin center-vertically-absolute() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin gray-border($radius: 12px) {
  border: 1px solid #e3e3e3;
  border-radius: $radius;
}
