@import 'bootstrap/scss/bootstrap';
@import 'intl-tel-input/build/css/intlTelInput';
@import 'ngx-bootstrap/datepicker/bs-datepicker.css';
@import 'apps/my-amica/src/scss/variables';
@import 'apps/my-amica/src/scss/form';
@import 'apps/my-amica/src/scss/modal';
@import 'apps/my-amica/src/scss/typography';
@import 'apps/my-amica/src/scss/buttons';
@import './scss/typography';

html {
  scroll-behavior: smooth;
}

img.ng-lazy {
  transition: opacity 1s;
  opacity: 0;

  &.ng-lazyloaded {
    opacity: 1;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1096px !important;
    position: relative;
  }
}

#toast-container.toast-top-right {
  top: 182px;
}

.ngx-toastr.toast-error {
  background-color: $color_red;
}

$transition-time: 0.3s;

a:hover {
  .plus {
    background-color: #fff;

    &:after {
      color: $color_red-2;
    }
  }
}

.plus {
  position: absolute;
  width: 60px;
  height: 60px;
  background-color: $color_red-2;
  border-radius: 50%;
  left: 35px;
  transition: all $transition-time;
  border: 2px solid $color_red-2;

  &:after {
    transition: all $transition-time;
    content: '+';
    color: #fff;
    left: 14px;
    font-size: 55px;
    font-weight: 300;
    line-height: 55px;
  }
}

.registered-product-complaint {
  position: absolute;
  background: $color_red;
  left: 0;
  top: 0;
  color: #fff;
  z-index: 123;
  padding: 0.6rem 0.8rem 0.7rem 0.6rem;
  border-bottom-right-radius: 24px;
}

.amm-promotion-ask-about-registered-product {
  position: relative;
  z-index: 1;
}

.text-decoration-underline {
  text-decoration: underline;
}

.amm-tooltip.tooltip {
  .tooltip-inner {
    background: $color_red;
  }

  .arrow::before {
    border-left-color: $color_red;
  }
}

.overlay-container {
  .toast-container {
    z-index: 999999;
    position: fixed;
  }
}

.iti__country-list {
  max-height: 200px;
}

// synerise styles
.persooa_widget_gif {
  img {
    display: block;
    width: 100%;
    height: auto;
  }
}
